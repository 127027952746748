import './home.css'; // Import your CSS

const Home = () => {

    return (
        <div className="home-container">
            <p1>Hello</p1>
        </div>
    );
};

export default Home;

import './chess.css'; // Import your CSS
import React, { useState } from 'react';

const Chess = () => {
    // Initialize the chess board
    const initializeBoard = () => {
        const board = [];
        const emptyRow = Array(8).fill(null);

        // Setup the pawns
        board[6] = Array(8).fill('P'); // White pawns
        board[1] = Array(8).fill('p'); // Black pawns

        // Setup the major pieces
        board[7] = ['R', 'N', 'B', 'Q', 'K', 'B', 'N', 'R']; // White pieces
        board[0] = ['r', 'n', 'b', 'q', 'k', 'b', 'n', 'r']; // Black pieces

        // Setup empty rows
        for (let i = 2; i < 6; i++) {
            board[i] = [...emptyRow];
        }

        return board;
    };

    // State to manage the board and turns
    const [board, setBoard] = useState(initializeBoard());
    const [draggingPiece, setDraggingPiece] = useState(null);
    const [isWhiteTurn, setIsWhiteTurn] = useState(true); // True for white's turn, false for black's turn

    // Determine if a move is valid
    const isValidMove = (from, to) => {
        const piece = board[from.row][from.col];
        const target = board[to.row][to.col];

        // Prevent moving opponent's pieces
        if ((isWhiteTurn && piece === piece.toLowerCase()) || (!isWhiteTurn && piece === piece.toUpperCase())) {
            return false;
        }

        // Determine the direction and distance of the move
        const rowDiff = Math.abs(to.row - from.row);
        const colDiff = Math.abs(to.col - from.col);

        // Pawn movement
        if (piece.toLowerCase() === 'p') {
            const isWhite = piece === 'P';
            const direction = isWhite ? -1 : 1;

            // Standard one-square forward move
            if (colDiff === 0 && to.row === from.row + direction && target === null) {
                return true;
            }

            // Two-square initial move
            if (
                colDiff === 0 &&
                to.row === from.row + 2 * direction &&
                target === null &&
                ((isWhite && from.row === 6) || (!isWhite && from.row === 1)) &&
                board[from.row + direction][from.col] === null
            ) {
                return true;
            }

            // Diagonal capture
            if (colDiff === 1 && to.row === from.row + direction && target !== null) {
                return true;
            }

            return false;
        }

        // Rook movement
        if (piece.toLowerCase() === 'r') {
            if (rowDiff === 0 || colDiff === 0) {
                return isPathClear(from, to);
            }
            return false;
        }

        // Knight movement
        if (piece.toLowerCase() === 'n') {
            return (rowDiff === 2 && colDiff === 1) || (rowDiff === 1 && colDiff === 2);
        }

        // Bishop movement
        if (piece.toLowerCase() === 'b') {
            return rowDiff === colDiff && isPathClear(from, to);
        }

        // Queen movement
        if (piece.toLowerCase() === 'q') {
            return (rowDiff === colDiff || rowDiff === 0 || colDiff === 0) && isPathClear(from, to);
        }

        // King movement
        if (piece.toLowerCase() === 'k') {
            return rowDiff <= 1 && colDiff <= 1;
        }

        return false;
    };

    // Check if the path is clear for rooks, bishops, and queens
    const isPathClear = (from, to) => {
        const rowDirection = Math.sign(to.row - from.row);
        const colDirection = Math.sign(to.col - from.col);

        let currentRow = from.row + rowDirection;
        let currentCol = from.col + colDirection;

        while (currentRow !== to.row || currentCol !== to.col) {
            if (board[currentRow][currentCol] !== null) {
                return false;
            }
            currentRow += rowDirection;
            currentCol += colDirection;
        }

        return true;
    };

    // Handle dragging a piece
    const handleDragStart = (row, col) => {
        setDraggingPiece({ row, col });
    };

    const handleDrop = (row, col) => {
        if (draggingPiece) {
            const from = draggingPiece;
            const to = { row, col };

            if (isValidMove(from, to)) {
                const updatedBoard = [...board];

                // Move the piece
                updatedBoard[to.row][to.col] = updatedBoard[from.row][from.col];
                updatedBoard[from.row][from.col] = null;

                setBoard(updatedBoard);

                // Toggle turn
                setIsWhiteTurn(!isWhiteTurn);
            }

            setDraggingPiece(null); // Clear the dragging piece
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault(); // Prevent the default dragover behavior
    };

    // Render the chess board
    const renderBoard = () => {
        return board.map((row, rowIndex) => (
            <div key={rowIndex} className="row">
                {row.map((cell, colIndex) => (
                    <div
                        key={colIndex}
                        className="cell"
                        draggable={cell !== null} // Only allow dragging pieces
                        onDragStart={() => handleDragStart(rowIndex, colIndex)}
                        onDragOver={handleDragOver}
                        onDrop={() => handleDrop(rowIndex, colIndex)}
                    >
                        {cell}
                    </div>
                ))}
            </div>
        ));
    };

    return (
        <div className="home-container">
            <h2>{isWhiteTurn ? "White's Turn" : "Black's Turn"}</h2>
            <div className="board-container">{renderBoard()}</div>
        </div>
    );
};

export default Chess;

import './App.css';
import Home from './home/home'; 
import Chess from './chess/chess';
import Navbar from './navbar/navbar';
import { Routes, Route } from 'react-router-dom';

// App
function App() {
  return (
    <div>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/chess" element={<Chess />} />
      </Routes>
    </div>
  );
}

export default App;
